<template>
  <div
    style="display: flex;
    justify-content: center;"
  >
    <b-card
      class="mb-0"
      style="width: 920px;
      height: 840px;"
    >
      <b-row class="auth-inner m-0 justify-content-center">
        <b-col>
          <div class="auth-wrapper auth-v2">
            <div style="position: absolute; top: 50px; left: 20px;">
              <img
                class="support-icon"
                src="@/assets/images/logo/curfox1.png"
                style="height: 180px;
                width: auto;"
              >
              <div style="margin-top: 10px;">
                <h3><strong>Parallax Technologies (Private) Limited</strong></h3>
                <h7> 125/2 3rd Lane, Nugagoda</h7><br>
                <h7>Subhadrarama Road</h7><br>
                <h7>nugegoda western</h7><br>
                <h7>Sri Lanka</h7>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div style="position: absolute; top: 50px; left:700px; text-align: right;">
            <h2 style="font-size: 3rem; margin: 0;">
              Invoice</h2>
            <h4>#CFC8670000</h4>
            <b>
              <h7>Balance Due</h7><br>
              <h3>$ 243</h3>
            </b>
          </div>
        </b-col>
      </b-row>
      <b-row class="auth-inner m-0 justify-content-center">
        <b-col>
          <div class="auth-wrapper auth-v2">
            <div style="position: absolute; top: 390px; left: 20px;">
              <h7>Bill To :</h7><br>
              <h4><strong>E Books Service (Private) Limited</strong></h4>
              <h7>Kalikasthan, Dillibazar</h7><br>
              <h7>Nepal</h7><br>
            </div>
          </div>
        </b-col>
        <b-col>
          <div style="position: absolute; top: 390px; left:550px; text-align: right;">
            <h7>Invoice Date  :</h7><br>
            <h7>Terms  :</h7><br>
            <h7>Due Date  :</h7><br>
          </div>
        </b-col>
        <b-col>
          <div style="position: absolute; top: 390px; left:750px; text-align: right;">
            <h7>28 Feb 2023</h7><br>
            <h7>Custom</h7><br>
            <h7>20 April 2023</h7><br>
          </div>
        </b-col>
      </b-row>
      <b-row class="auth-inner m-0 justify-content-center">
        <b-col>
          <div class="auth-wrapper auth-v2">
            <div style="position: absolute; top:500px; left: 20px;">
              <h7>Subject</h7><br>
              <h7>Curfox Invoice</h7>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="auth-inner m-0 justify-content-center">
        <b-col>
          <div
            class="auth-wrapper auth-v2"
            style="background-color: rgb(13, 13, 13);
            width: 860px;
            position: absolute;
            top: 560px;
            left: 20px;"
          >
            <table style="width: 100%; height: 30px;">
              <tr>
                <td style="width: 10%; color: white;">
                  <strong>#</strong></td>
                <td style="width: 54%; color: white;">
                  <strong>Item & Descriptions</strong></td>
                <td style="width: 12%; color: white;">
                  <strong>Qty</strong></td>
                <td style="width: 12%; color: white;">
                  <strong>Rate</strong></td>
                <td style="width: 12%; color: white;">
                  <strong>Amount</strong></td>
              </tr>
            </table>
          </div>
          <div
            class="auth-wrapper auth-v2"
            style="width:860px;
            position: absolute;
            top: 600px;
            left: 20px;"
          >
            <table style="width: 100%; height: 30px;">
              <tr>
                <td style="width: 10%;">
                  1</td>
                <td style="width: 54%;">
                  Minimum Order Count Change for the Month of February</td>
                <td style="width: 12%;">
                  225.00</td>
                <td style="width: 12%;">
                  0.05</td>
                <td style="width: 12%;">
                  12.75</td>
              </tr>
            </table>
          </div>
          <hr style="width: 860px; position: absolute; top: 620px; left: 20px; border-color: rgb(76, 74, 74);">
        </b-col>
      </b-row>
      <b-row class="auth-inner m-0 justify-content-center">
        <b-col>
          <div
            class="auth-wrapper auth-v2"
            style="position: absolute;
            top: 660px;
            left: 610px;
            color: rgb(71, 67, 67);
            text-align: right;"
          >
            <h7>Sub Total</h7><br><br>
            <h7 style="width: 10%; color: rgb(19, 17, 17);">
              <strong>Total</strong></h7><br><br>
            <h7 style="width: 10%; color: rgb(19, 17, 17);">
              <strong>Balance Due</strong></h7>
          </div>
        </b-col>
        <b-col>
          <div
            class="auth-wrapper auth-v2"
            style="position: absolute;
            top: 660px;
            left: 780px;
            color: rgb(71, 67, 67);"
          >
            <h7>12.75</h7><br><br>
            <h7 style="width: 10%; color: rgb(19, 17, 17);">
              <strong>$12.75</strong></h7><br><br>
            <h7 style="width: 10%; color: rgb(19, 17, 17);">
              <strong>$12.75</strong></h7>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCard,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    // validations
    BRow,
    BCard,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      showNotification: true,
      status: '',
      otpcode: '',
      // validation
      required,
    }
  },
  methods: {
  },
}
/* eslint-disable global-require */
</script>
<style>
.search-btn {
  height: 30px;
  width: 30px;
  margin-left: 3px;
  cursor: pointer;
}
.home-btn {
  height: 30px;
  width: 30px;
  margin-left: 3px;
  cursor: pointer;
}
.notification {
  background-color: rgb(29, 164, 29);
  color: white;
  padding: 20px;
  border-radius: 5px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 30px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
}
</style>
